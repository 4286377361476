<template>
  <v-container>
    <v-row v-if="ready">
      <spinner style="margin: 300px 500px;"></spinner>
    </v-row>
    <div v-else>
      <div class="ml-n5">
        <v-breadcrumbs
          :items="items"
          :to="items.name"
          divider=" - "
        ></v-breadcrumbs>
      </div>
      <v-card class="mx-auto" outlined>
        <v-list-item three-line>
          <v-list-item-content>
            <!--            <div>-->
            <!--              Source-->
            <!--            </div>-->
            <v-list-item-title>
              <h3>{{ details.name }}</h3>
            </v-list-item-title>
            <v-list-item-subtitle>{{ details.address }} </v-list-item-subtitle>
            <v-list-item-subtitle
              >{{ details.phoneNumber }}
            </v-list-item-subtitle>
          </v-list-item-content>

          <v-list-item-avatar tile size="80" color="grey"></v-list-item-avatar>
        </v-list-item>
      </v-card>
      <v-card class="d-flex flex-wrap justify-space-around mb-6"> </v-card>
    </div>
  </v-container>
</template>

<script>
import * as axios from "axios";
import Spinner from "vue-simple-spinner";

export default {
  name: "SourceDetails",
  components: { spinner: Spinner },
  data: () => ({
    details: {},
    ready: true,
    name: "",
    location: "",
    phone: "",
    region: "",
    items: [
      {
        text: "Sources",
        disabled: false,
        name: "master.sources"
      },
      {
        text: "Source",
        disabled: false
      }
    ]
  }),
  methods: {
    getBranch() {
      let id = this.$route.params.id;
      console.log(id);
      axios({
        method: "get",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`
        },
        url: `${process.env.VUE_APP_BASE}/sources/${id}`
      })
        .then(response => {
          if (response.status === 200) {
            this.details = response.data;
            this.ready = false;
            //console.log(response);
          }
        })
        .catch(err => {
          if (err.response.status === 401) {
            this.$router.replace({ name: "/login" });
          } else {
            //console.log(err);
          }
        });
    }
  },
  created() {
    this.getBranch();
  }
};
</script>

<style scoped></style>
